import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'

import '../../../styles/tipos-apartamentos.scss'

export default function Tipos({ location }) {
	return (
		<Layout currentPath={location}>
			<SEO title="Ínsula Living | Tipos" />
			<div className="insula-tipos">
				<Link to="/proyectos/insula-living" className="back">
					&larr;Volver
				</Link>
				<div className="insula-tipos__title">
					<div className="insula-tipos__texture" />
					<h1>ÍNSULA LIVING JUANANBÚ</h1>
				</div>
				<div className="insula-tipos__buttons-container">
					<Link to="/proyectos/insula-living/suites">SUITES</Link>
					<Link to="/proyectos/insula-living/hobbie-workspot">HOBBIE AND WORKSPOT</Link>
					<Link to="/proyectos/insula-living/double">DOUBLE</Link>
					<Link to="/proyectos/insula-living/loft">LOFT</Link>
				</div>
			</div>
		</Layout>
	)
}
